import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

export const LinkMap = {
  "double screen": "75fa414e809e",
  spectableapp: "75fa414e809e",
  rectangleapp: "75fa414e809e",
  sidecar: "75fa414e809e",
  speedtyper: "75fa414e809e",
  notion: "75fa414e809e",

  "redux-dev-tools": "75a21c30c3c6",
  "redux inspector": "75a21c30c3c6",
  "redux action": "75a21c30c3c6",
  "redux state": "75a21c30c3c6",
  "redux trace": "75a21c30c3c6",
  "redux diff": "75a21c30c3c6",
  "redux test": "75a21c30c3c6",
  "Chrome Performance": "35a05c7ba299",
  "Performance FPS": "35a05c7ba299",
  "Performance CPU": "35a05c7ba299",
  "Performance HEAP": "35a05c7ba299",
  "Performance NET": "35a05c7ba299",
  "Performance FCP": "35a05c7ba299",
  "Performance LCP": "35a05c7ba299",
  "Performance JS HEAP": "35a05c7ba299",
  "Performance GPU MEM": "35a05c7ba299",

  ESLint: "d97e5573cc46",
  Prettier: "dc3a9322c437",
  "eslintrc.json": "d97e5573cc46",
  "Prettier + ESLint": "c75cce4ae430",

  "Yumod Chrome Extension": "235c1a63921e",

  ".git": "51ec0c07a434",
  ".gitignore": "51ec0c07a434",
  "git init": "51ec0c07a434",
  "git status": "51ec0c07a434",
  "git add": "51ec0c07a434",
  "git reset HEAD": "51ec0c07a434",
  "git diff": "51ec0c07a434",
  "git checkout": "51ec0c07a434",
  "git commit": "51ec0c07a434",
  "git push": "51ec0c07a434",
  "git pull": "51ec0c07a434",
  "git logs": "51ec0c07a434",
  "git shows": "51ec0c07a434",
  "git branch": "51ec0c07a434",

  "intellij file format": "2ccb278af297",

  "docker pull": "a0b561ba3f1",
  "docker images": "a0b561ba3f1",
  "docker rmi": "a0b561ba3f1",
  "docker ps": "a0b561ba3f1",
  "docker stop imageID": "a0b561ba3f1",
  "docker ps -a": "a0b561ba3f1",
  "docker stop $(docker ps -a -q)": "a0b561ba3f1",
  "docker exec -it": "a0b561ba3f1",
  "docker commit imageID": "a0b561ba3f1",
  "docker push": "a0b561ba3f1",
  "docker run -it -d -p": "a0b561ba3f1",
  "docker restart": "a0b561ba3f1",

  "App Distribution": "9ac34df68b47",
  "App Store": "9ac34df68b47",
  "Enroll/Develop/Distribute": "9ac34df68b47",
  Certificates: "9ac34df68b47",

  "Chrome Extension": "d1702fe36c25",
  Yumod: "d1702fe36c25",

  "Chrome Extension2": "f6087bc6db4b",
  Yumod2: "f6087bc6db4b",

  "Digital Gardening": "4e9467653de7",
  "Learn In Public": "4e9467653de7",

  RunJS: "3005ce6c121f",

  JSONServer: "9fd189351d1d",
  Mockoon: "9fd189351d1d",
  "Mock Service Worker": "9fd189351d1d",

  "GitHub Actions": "937e20fbd33d",
  "GitHub Actions 2": "c70734f95db4",
}

const storiesGroup = [
  {
    title: "Geliştirme Ortamı",
    storyPart: [
      {
        title: "Geliştirme Ortamını İyileştirme",
        postId: "75fa414e809e",
      },
      {
        title: "Geliştirme Ortamını İyileştirme-2",
        postId: "2e81532f7707",
      },
      {
        title: "Geliştirme Ortamını İyileştirme-3",
        postId: "e9a1bff714d3",
      },
      {
        title: "Geliştirme Ortamını İyileştirme-4 (React)",
        postId: "b9e8454a089a",
      },
    ],
  },
  {
    title: "Deney Ortamı",
    storyPart: [
      {
        title: "Digital Gardening (Öğrenme Yolu)",
        postId: "4e9467653de7",
      },
      {
        title: "JS Playground → RunJS",
        postId: "3005ce6c121f",
      },
      {
        title: "Backend Mocklama Yöntemleri",
        postId: "9fd189351d1d",
      },
    ],
  },
  {
    title: "IDE Plugins",
    storyPart: [
      {
        title: "ESLint Nedir ? Ne İşe Yarıyor ?",
        postId: "d97e5573cc46",
      },
      {
        title: "Prettier Nedir? Nasıl Kullanılır?",
        postId: "dc3a9322c437",
      },
      {
        title: "Prettier Import Sort",
        postId: "8e9442f2dbb1",
      },
      {
        title: "Prettier+ Eslint Nasıl Kullanılır?",
        postId: "c75cce4ae430",
      },
      {
        title: "ESLint ve Prettier Birlikte Kullanılması -2",
        postId: "e45391c2df3e",
      },
      {
        title: "Husky ve GitHub Actions Otomasyon",
        postId: "ef8552389f29",
      },
      {
        title: "Husky ile Otomasyon",
        postId: "2bae42f7741",
      },
    ],
  },
  {
    title: "Chrome Tools",
    storyPart: [
      {
        title: "React DevTools Kullanımı ",
        postId: "7a81c64601ff",
      },
      {
        title: "Redux DevTools Kullanımı ",
        postId: "75a21c30c3c6",
      },
      {
        title: "Chrome ile Uygulama Performans Analizi",
        postId: "35a05c7ba299",
      },

      {
        title: "Medium için Chrome Eklentisi",
        postId: "d1702fe36c25",
      },
      {
        title: "A Chrome Extension for Medium",
        postId: "f6087bc6db4b",
      },
      {
        title: "Teknolojik Araştırmalarınızı İyileştirme",
        postId: "235c1a63921e",
      },
    ],
  },
  {
    title: "Redux DevTools",
    storyPart: [
      {
        title: "React Query DevTools -1",
        postId: "fc1e1b5b43b7",
      },
      {
        title: "React Query DevTools -2",
        postId: "9ee8114d1f15",
      },
      {
        title: "React Query DevTools -3",
        postId: "cc9e08648fde",
      },
    ],
  },
  {
    title: "GitHub Kullanımı",
    storyPart: [
      {
        title: "React Projelerinde Git Branch Stratejileri",
        postId: "92622613ae9d",
      },
      {
        title: "React Projelerinde Git Branch İsimlendirme",
        postId: "bfd64885d64d",
      },
      {
        title: "Github Actions ile Otomatik Deploy",
        postId: "937e20fbd33d",
      },
      {
        title: "Github Actions ile Manuel Deploy",
        postId: "c70734f95db4",
      },
    ],
  },
  {
    title: "Komutla Kullanım",
    storyPart: [
      {
        title: "Docker Komutları",
        postId: "a0b561ba3f1",
      },
      {
        title: "Git Komutları",
        postId: "51ec0c07a434",
      },
    ],
  },
  {
    title: "Diger",
    storyPart: [
      {
        title: "IntelliJ Dosya Formatı Atama",
        postId: "2ccb278af297",
      },
      {
        title: "IOS App Distribution (Uygulama Dağıtımı)",
        postId: "9ac34df68b47",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Frontend Geliştirici Araçları",
  path: "gelistirici-araclari",
}

const DevToolsPage = () => {
  return (
    <StoryGroup
      complex={true}
      title={context.title}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default DevToolsPage
